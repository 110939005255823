var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("bm-control", [
    _c("a", {
      staticClass: "baidu-map-fullscreen",
      attrs: { href: "#" },
      domProps: {
        innerHTML: _vm._s(
          _vm.fullscreen ? _vm.svg.fullscreenExit : _vm.svg.fullscreen
        ),
      },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.toggle()
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }