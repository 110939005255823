var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "baidu-map",
    {
      staticClass: "supplier__map",
      attrs: {
        id: "supplierMap",
        center: _vm.baiduCoordinates,
        "scroll-wheel-zoom": _vm.scrollWheelZoom,
        zoom: _vm.zoom,
        ak: _vm.ak,
        mapStyle: _vm.mapStyle,
      },
      on: { ready: _vm.ready },
    },
    [
      _c("bm-navigation", { attrs: { anchor: "BMAP_ANCHOR_TOP_RIGHT" } }),
      _vm._v(" "),
      _vm.baiduCoordinates
        ? _c("bm-marker", {
            attrs: { position: _vm.baiduCoordinates, icon: _vm.markerStyle },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("bm-fullscreen"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }