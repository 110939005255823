<template>
  <bm-control>
    <a
      href="#"
      class="baidu-map-fullscreen"
      @click.prevent="toggle()"
      v-html="fullscreen ? svg.fullscreenExit : svg.fullscreen"
    ></a>
  </bm-control>
</template>

<script>
import BmControl from 'vue-baidu-map/components/controls/Control.vue';

import fullscreen from 'apps/public/img/baseline-fullscreen-24px.svg';
import fullscreenExit from 'apps/public/img/baseline-fullscreen-exit-24px.svg';

export default {
  components: {
    BmControl,
  },
  data() {
    return {
      fullscreen: false,
      svg: {
        fullscreen,
        fullscreenExit,
      },
    };
  },
  methods: {
    // Based on https://developer.mozilla.org/en-US/docs/Web/API/Fullscreen_API/Guide
    toggle() {
      if (document.fullscreenElement) {
        if (document.exitFullscreen) document.exitFullscreen();
      }

      const $el = this.$parent.$el;

      if ($el.requestFullscreen) $el.requestFullscreen();
    },
    fullscreenchange() {
      this.fullscreen = !!document.fullscreenElement;
    },
  },
  mounted() {
    document.addEventListener('fullscreenchange', this.fullscreenchange);
  },
  beforeDestroy() {
    document.removeEventListener('fullscreenchange', this.fullscreenchange);
  },
};
</script>

<style lang="scss">
@use 'sass:math';

// Based on GMaps styling
.baidu-map-fullscreen {
  background: none rgb(255, 255, 255);
  border: 0;
  margin: math.div(1rem, 1.6);
  padding: 0;
  position: absolute;
  cursor: pointer;
  user-select: none;
  border-radius: 2px;
  height: math.div(3.2rem, 1.6);
  width: math.div(3.2rem, 1.6);
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px math.div(0.4rem, 1.6) -1px;
  overflow: hidden;
  top: 0;
  left: 0;

  svg {
    margin: math.div(0.4rem, 1.6);
    height: math.div(2.4rem, 1.6);
    width: math.div(2.4rem, 1.6);
    max-height: 100%;
    max-width: 100%;
    fill: rgb(63, 110, 201);
  }
}
</style>
