<template>
  <baidu-map
    id="supplierMap"
    class="supplier__map"
    :center="baiduCoordinates"
    :scroll-wheel-zoom="scrollWheelZoom"
    :zoom="zoom"
    :ak="ak"
    :mapStyle="mapStyle"
    @ready="ready"
  >
    <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
    <bm-marker :position="baiduCoordinates" :icon="markerStyle" v-if="baiduCoordinates"></bm-marker>
    <bm-fullscreen></bm-fullscreen>
  </baidu-map>
</template>

<script>
import coordtransform from 'coordtransform';
import BaiduMap from 'vue-baidu-map/components/map/Map.vue';
import BmNavigation from 'vue-baidu-map/components/controls/Navigation.vue';
import BmMarker from 'vue-baidu-map/components/overlays/Marker.vue';
import BmFullscreen from 'apps/public/pages/components/map/baidu-fullscreen-control.vue';

import baiduMapsStyleGetter from '../../../lib/baiduMapsStyleGetter';

// SVG
import pinMarker from '../../../img/pin-marker.svg';

export default {
  components: { BaiduMap, BmNavigation, BmMarker, BmFullscreen },
  props: {
    supplier: {
      default: {},
    },
  },
  data() {
    return {
      scrollWheelZoom: false,
      zoom: 14,
      ak: APP_CONFIG.baidu.maps.ak,
      mapStyle: { styleJson: baiduMapsStyleGetter() },
      markerStyle: {
        url: `data:image/svg+xml;base64,${window.btoa(pinMarker)}`,
        size: {
          width: 30,
          height: 30,
        },
        anchor: {
          width: 30,
          height: 30,
        },
      },
    };
  },
  computed: {
    // REFACTOR into coordinates helper since we have exactly the same code in google-map (same folder) - and possibly other locations as well
    coordinates() {
      if (this.supplier.coordinates) {
        return {
          latitude: this.supplier.coordinates.split(', ')[0],
          longitude: this.supplier.coordinates.split(', ')[1],
        };
      } else {
        return null;
      }
    },
    baiduCoordinates() {
      if (!this.supplier.coordinates) return null;

      const [lng, lat] = coordtransform.gcj02tobd09(
        ...coordtransform.wgs84togcj02(this.coordinates.longitude, this.coordinates.latitude)
      );

      return {
        lng,
        lat,
      };
    },
  },
  methods: {
    // Baidu Maps
    ready({ BMap, map }) {
      this.BMap = BMap;
      this.map = map;
    },
    keydown(event) {
      // Ctrl key pressed
      if (event.keyCode === 17) {
        this.scrollWheelZoom = true;
      }
    },
    keyup(event) {
      // Ctrl key pressed
      if (event.keyCode === 17) {
        this.scrollWheelZoom = false;
      }
    },
  },
  mounted() {
    if (this.supplier.coordinates) {
      document.addEventListener('keydown', this.keydown);
      document.addEventListener('keyup', this.keyup);
    }
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.keydown);
    document.removeEventListener('keyup', this.keyup);
  },
};
</script>

<style lang="scss">
@use 'sass:math';
</style>
